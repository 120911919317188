// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import Turbolinks from "turbolinks"
// import * as ActiveStorage from "@rails/activestorage"
// import "channels"

Rails.start()
Turbolinks.start()
// ActiveStorage.start()

import "bootstrap"
import "controllers"
import "../scripts/power_plants_sort"
import "../scripts/factor_select"
import "../scripts/settings_power_plants"
import "../scripts/bulk_release"
import "../scripts/bulk_release_disable"
import "../stylesheets/application"
require("jquery")
require("jquery-ui/ui/widgets/datepicker")
require('jquery-ui/ui/widgets/sortable');
require('select2')
require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")

// js.erbでjQueryを使用するため
window.$ = jQuery;

// 画像ファイルの読み込み
const images = require.context('../images/', true)
